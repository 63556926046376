// @ts-check
import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

interface SEOProps {
  description?: string
  lang?: string
  title: string
  meta?: Record<string, string>[]
  enableCookiebot?: boolean
}

const SEO = ({
  description,
  lang = "en",
  meta = [],
  title,
  enableCookiebot,
}: SEOProps) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={`%s | ${site.siteMetadata.title}`}
        meta={[
          {
            name: "description",
            content: metaDescription,
          },
          {
            property: "og:title",
            content: title,
          },
          {
            property: "og:description",
            content: metaDescription,
          },
          {
            property: "og:type",
            content: "website",
          },
          {
            name: "twitter:card",
            content: "summary",
          },
          {
            name: "twitter:title",
            content: title,
          },
          {
            name: "twitter:description",
            content: metaDescription,
          },
          {
            name: "google-site-verification",
            content: "SLNLTbPmFHs854BGYNq91SZiAEzgtWbRrP1ff9ZGrMo",
          },
        ].concat(meta)}
      >
        {enableCookiebot && (
          <script
            id="Cookiebot"
            src="https://consent.cookiebot.com/uc.js"
            data-cbid="238171a5-99a8-4cf1-af8a-3dcc84f7150e"
            data-blockingmode="auto"
            type="text/javascript"
          />
        )}
      </Helmet>
    </>
  )
}

export default SEO
