// @ts-check
import React from "react"
import styled from "@emotion/styled"

import colors, { foregroundColors } from "../styles/colors"
import {
  borderRadius,
  MobileMediaQuery,
  MOBILE_PADDING_HORIZONTAL,
  MOBILE_PADDING_VERTICAL,
  PADDING_HORIZONTAL,
} from "../styles/constants"
import Typography, { typographyComponents } from "./typography"

/**
 * @typedef {object} CardWrapperProps
 * @prop {import("../styles/colors").TColor} [variant="dark"] - The color used for the background of the card. The text color will be adjusted automatically
 * @prop {boolean} [isHeader=false]
 * @prop {boolean} [isFullWidthMobile=false]
 * @prop {boolean} [isFixedHeight=false]
 * @prop {boolean} [hasImg=false] - If the card wrapper has an image, the text will be white on desktop. Otherwise, it will be of the foreground color for the card
 */

/** @type {import("@emotion/styled").StyledComponent<CardWrapperProps>} */
export const CardWrapper = styled.div`
  position: relative;
  max-width: 1200px;
  background-color: ${p => colors[p.variant || "dark"]};
  color: ${p => foregroundColors[p.variant || "dark"]};
  border-radius: ${borderRadius};
  margin: 0 auto 40px;
  overflow-x: hidden;
  height: ${p => ((p.isFixedHeight ? "600px" : "initial"))};

  ${MobileMediaQuery} {
    height: initial;
    margin: ${p => ((p.isFullWidthMobile ? "0 -4px" : "0 auto 4px"))};
    width: ${p => ((p.isFullWidthMobile ? "100vw" : "initial"))}
    }
  }

  @media (min-width: 769px) {
    ${p => ((p.hasImg ? "color: white;" : ""))};
  }
  @media screen and (max-width: 1200px) {
    border-radius: ${borderRadius};
  }
`

const ImgWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: ${borderRadius};
  overflow: hidden;

  ${MobileMediaQuery} {
    position: static;
  }
`

export const CardLateralWrapper = styled.div`
  max-width: 1240px;
  margin: 0 auto;
  padding-left: ${p => (p.noLateralPadding ? 0 : PADDING_HORIZONTAL)}px;
  padding-right: ${p => (p.noLateralPadding ? 0 : PADDING_HORIZONTAL)}px;

  ${MobileMediaQuery} {
    padding-left: ${MOBILE_PADDING_HORIZONTAL}px;
    padding-right: ${MOBILE_PADDING_HORIZONTAL}px;
  }
`

/** @type {import("@emotion/styled").StyledComponent<{ center?: boolean, noMinHeight?: boolean, isFixedHeight?: boolean }>} */
export const CardContentWrapper = styled(CardLateralWrapper)`
  display: flex;
  flex-direction: column;
  align-items: ${p => ((p.center ? "center" : "flex-start"))};
  text-align: ${p => ((p.center ? "center" : "left"))};
  position: relative;
  flex: 1;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
  min-height: ${p => ((!p.noMinHeight ? "600px" : "initial"))};
  height: ${p => ((p.isFixedHeight ? "600px" : "initial"))};

  /* Vertical align content when there is an image */
  ${ImgWrapper} ~ & {
    justify-content: center;
  }

  ${MobileMediaQuery} {
    padding-top: ${MOBILE_PADDING_VERTICAL}px;
    padding-bottom: ${MOBILE_PADDING_VERTICAL}px;
    min-height: initial;
  }
`

export const CardTitle = styled(Typography)`
  margin-bottom: 40px;
  ${(/** @type {{maxWidth?: number}} */ p) =>
    p.maxWidth ? `max-width: ${p.maxWidth}px;` : ""}

  ${MobileMediaQuery} {
    margin-bottom: 10px;
  }
`

export const CardCaption = styled(typographyComponents.bodySmall)`
  display: block;
  max-width: ${(/** @type {{maxWidth?: number}} */ p) => p.maxWidth || 400}px;
`

/**
 * @typedef {object} IntrinsicCardProps
 * @property {React.ReactNode} [img]
 * @property {boolean} [isHeader]
 * @property {boolean} [center]
 * @property {boolean} [noMinHeight]
 * @property {boolean} [isFixedHeight]
 * @property {boolean} [isFullWidthMobile]
 * @property {boolean} [noLateralPadding]
 * @property {React.ReactNode} [children]
 */

/** @typedef {IntrinsicCardProps & CardWrapperProps & React.HTMLAttributes<HTMLDivElement>} CardProps */

/**
 * This is a helper function to create a Card easily
 * @param {CardProps} props
 */
const Card = ({
  img = null,
  isHeader = false,
  center = false,
  noMinHeight,
  isFixedHeight = false,
  children = null,
  noLateralPadding = false,
  className,
  isFullWidthMobile,
  ...props
}) => (
  <CardWrapper
    isHeader={isHeader}
    {...props}
    hasImg={Boolean(img)}
    isFullWidthMobile={isFullWidthMobile}
  >
    {img && <ImgWrapper>{img}</ImgWrapper>}
    <CardContentWrapper
      isFixedHeight={isFixedHeight}
      noMinHeight={noMinHeight}
      center={center}
      className={className}
      noLateralPadding={noLateralPadding}
    >
      {children}
    </CardContentWrapper>
  </CardWrapper>
)

export const DoubleCard = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0 auto;

  ${CardWrapper} {
    flex: 1;
    flex-shrink: 0;
    width: 100%;
  }

  ${CardContentWrapper} {
    padding-top: 80px;

    ${MobileMediaQuery}  {
      padding-top: ${MOBILE_PADDING_VERTICAL}px;
    }

    @media screen and (max-width: 1200px) {
      padding-left: 50px;
      padding-right: 50px;
    }

    @media screen and (max-width: 768px) {
      padding-left: 40px;
      padding-right: 40px;
    }

    @media screen and (max-width: 450px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  @media screen and (min-width: 769px) {
    ${CardWrapper}:first-of-type {
      margin-right: 4px;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export default Card
