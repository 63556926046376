import React from "react"
import styled from "@emotion/styled"
import { typographyComponents } from "./typography"
import colors from "../styles/colors"
import Link from "./link"
import { Helmet } from "react-helmet"
import Button from "./button"
import DemoContext from "../contexts/demo"

const NavWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  border-left: 1px solid rgb(240, 240, 240);

  background-color: white;
  color: ${colors.darkgrey};

  padding-top: 60px;

  visibility: ${p => (p.open ? "visible" : "hidden")};
`
const Top = styled.div``

const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
`

const MenuTextLink = styled(typographyComponents.h6)`
  text-decoration: none;
  color: inherit;
  padding: ${p => (p.subItem ? "0.3rem 0" : "0.5rem 0")};
`

const Buttons = styled.div`
  width: 100%;
`

const ScrollWrapper = styled.div`
  overflow: scroll;
  padding-bottom: 120px;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
`

const DemoButton = styled(Button)`
  text-transform: none;
  font-weight: normal;
  border-radius: 74px;
  width: 100%;
  height: 56px;

  margin-top: 10px;

  color: ${p => (p.ghost ? colors[p.variant] : colors.white)};
  border: 2px solid ${p => colors[p.variant]};
  background-color: ${p => (p.ghost ? "transparent" : colors[p.variant])};
  font-size: 20px !important;
  line-height: 26px !important;
`

const Separator = styled.hr`
  width: 100%;
  border: 0.5px solid ${colors.lightgrey};
`

export const MobileNav = ({
  items,
  demoButton,
  headerColor,
  open,
  onClose,
}) => {
  const { open: openDemo } = React.useContext(DemoContext)

  return (
    <>
      <Helmet>{open && <body className="disable-scroll" />}</Helmet>
      <NavWrapper open={open}>
        <ScrollWrapper>
          <Top>
            <MenuItems>
              {items.map((item, i) => (
                <React.Fragment key={item.linkTo || i}>
                  {item.buttonsBefore && (
                    <Buttons>
                      {demoButton === undefined ? (
                        <DemoButton
                          center
                          variant={headerColor}
                          onClick={openDemo}
                          size="s"
                          ghost
                        >
                          Get a demo
                        </DemoButton>
                      ) : (
                        demoButton
                      )}
                      <Separator style={{ marginTop: "18px" }} />
                    </Buttons>
                  )}
                  <MenuTextLink
                    onClick={onClose}
                    as={Link}
                    to={item.linkTo}
                    style={{ color: item.subItems && colors.grey }}
                  >
                    {item.name}
                  </MenuTextLink>
                  {item.subItems &&
                    item.subItems.map(subItem => (
                      <MenuTextLink
                        onClick={onClose}
                        key={subItem.linkTo}
                        subItem={true}
                        as={Link}
                        to={subItem.linkTo}
                      >
                        {subItem.name}
                      </MenuTextLink>
                    ))}
                  <Separator />
                </React.Fragment>
              ))}
            </MenuItems>
          </Top>
        </ScrollWrapper>
      </NavWrapper>
    </>
  )
}
