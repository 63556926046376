// @ts-check
import React from "react"
import styled from "@emotion/styled"

import { CardTitle, CardWrapper } from "../card"
import InputUI from "../input"
import { FUNCTIONS_ENDPOINT } from "../../config"
import axios from "axios"
import Button from "../button"
import Typography, { typographyComponents } from "../typography"
import Link from "../link"
import colors from "../../styles/colors"
import { identify, trackEvent } from "../../utils/trackEvent"
import { trackError } from "../../utils/segment"
import {
  BORDER_RADIUS_LARGE,
  MobileMediaQuery,
  MOBILE_PADDING_HORIZONTAL,
  PADDING_HORIZONTAL,
} from "../../styles/constants"
import Cross from "../icons/cross"

const Card = styled(CardWrapper)`
  padding-top: 50px;
  padding-bottom: 50px;
  max-width: 1440px;
  border-radius: ${BORDER_RADIUS_LARGE}px;
`

const ContentWrapper = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding-left: ${PADDING_HORIZONTAL}px;
  padding-right: ${PADDING_HORIZONTAL}px;

  ${MobileMediaQuery} {
    padding-left: ${MOBILE_PADDING_HORIZONTAL}px;
    padding-right: ${MOBILE_PADDING_HORIZONTAL}px;
  }
`

const Columns = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  @media (max-width: 550px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const Copy = styled.div`
  flex: 1;
  @media (min-width: 550px) {
    margin-right: 25px;
  }
`
const Form = styled.div`
  flex: 1;
  @media (max-width: 549px) {
    align-self: stretch;
  }
  @media (min-width: 550px) {
    margin-left: 25px;
  }
`.withComponent("form")

const FormButton = styled(Button)`
  position: relative;
  transition: color 0.2s;
  background-color: transparent;
  color: ${p => colors[p.variant]};
  border: 2px solid ${p => colors[p.variant]};
  border-radius: 10000px;
  text-transform: none;
  padding-left: 17px;
  margin-top: 40px;
  ${MobileMediaQuery} {
    margin-top: 30px;
  }

  &:hover {
    background-color: ${colors.primary};
    border-color: ${colors.primary};
    color: ${colors.white};
  }

  &:after {
    content: "Sending...";
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    color: ${colors.light};
    transform: translateX(-10px);
    transition: opacity 0.2s, transform 0.2s cubic-bezier(0.86, 0, 0.07, 1);
  }

  &[data-issending] {
    color: transparent;
    &:after {
      opacity: 1;
      transform: none;
    }
  }
`
const FormColumns = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  @media (max-width: 1040px) {
    flex-direction: column;
  }
`

const Caption = styled(typographyComponents.bodyLarge)`
  display: block;
  margin-bottom: 20px;
  max-width: 350px;

  ${MobileMediaQuery} {
    margin-bottom: 40px;
  }
`
const Input = styled(InputUI)`
  flex: 1;
  color: ${colors.darkgrey};

  @media (min-width: 1040px) {
    margin-bottom: 0;
    margin-right: 20px;
  }
`
const Terms = styled(typographyComponents.small)`
  display: block;
  margin-top: 20px;
  opacity: 0.55;
  a {
    color: inherit;
    text-decoration: underline;
  }

  ${MobileMediaQuery} {
    margin-top: 0;
  }
`
const Error = styled(Terms)`
  opacity: 1;
  color: ${colors.error};
`
const Status = styled(typographyComponents.h5)`
  display: block;
  margin-top: 20px;
`

const Label = styled(Typography)`
  display: inline-block;
  margin-bottom: 10px;
`

const CardNewsletter = () => {
  const [email, setEmail] = React.useState("")
  const [isSending, setIsSending] = React.useState(false)
  const [status, setStatus] = React.useState(
    /** @type {null | "success" | "failure"} */ (null)
  )
  return (
    <Card variant="darkgrey" noMinHeight>
      <ContentWrapper>
        <Columns>
          <Copy>
            <CardTitle variant="h3" as="h3" style={{ maxWidth: "300px" }}>
              Get exclusive content
            </CardTitle>

            <Caption>
              Tips, tricks, and things from the world of{" "}
              <strong>tech support</strong>. No&nbsp;spam,&nbsp;ever.
            </Caption>
          </Copy>
          <Form
            onSubmit={async e => {
              e.preventDefault()
              setIsSending(true)
              trackEvent({
                category: "generate_lead",
                label: "newsletter subscribe tried",
                siteSection: "newsletter_subscription_card",
              })
              identify(email)
              try {
                await axios.post(
                  `${FUNCTIONS_ENDPOINT}/newsletter-subscribe/`,
                  {
                    email,
                  }
                )
                setStatus("success")
                trackEvent({
                  category: "generate_lead",
                  label: "newsletter subscribe success",
                  siteSection: "newsletter_subscription_card",
                })
              } catch (err) {
                setStatus("failure")
                trackError(err)
              } finally {
                setIsSending(false)
              }
            }}
          >
            {status !== "success" ? (
              <>
                <Label variant="h6">Your business email</Label>
                <FormColumns>
                  <Input
                    focusBorderVariant="darkgrey"
                    id="newsletter-email"
                    variant="grey"
                    required
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </FormColumns>
                {status !== "failure" ? (
                  <Terms>
                    By subscribing to our newsletter, you agree to our{" "}
                    <Link to="/privacy-policy/" forceLanguage="en">
                      Privacy&nbsp;Policy
                    </Link>
                  </Terms>
                ) : (
                  <Error>
                    An error occured while subscribing you to our newsletter.
                    Please try again shortly
                  </Error>
                )}
                <FormButton
                  type="submit"
                  variant="primary"
                  disabled={isSending}
                  data-issending={isSending || null}
                >
                  <Cross
                    strokeWidth="6"
                    style={{
                      marginRight: "16px",
                      width: "16px",
                      height: "16px",
                    }}
                  />
                  Subscribe
                </FormButton>
              </>
            ) : (
              <Status>
                Success! Useful content coming to your inbox&nbsp;shortly.
              </Status>
            )}
          </Form>
        </Columns>
      </ContentWrapper>
    </Card>
  )
}

export default CardNewsletter
