// @ts-check
import { Global as EmotionGlobal, css } from "@emotion/react"
import React from "react"
import colors, { foregroundColors } from "./colors"

/**
 * @param {Object} props
 * @param {import("./colors").TColor} props.bg
 */
const Global = ({ bg }) => (
  <EmotionGlobal
    styles={css`
      html {
        font-family: TTMavenoid, "SpaceGrotesk", Helvetica, sans-serif;
        margin: 0;
      }

      body {
        margin: 0;
        padding: 0;
        box-sizing: border-box;

        background-color: ${colors[bg]};
        color: ${foregroundColors[bg]};
      }

      *,
      *::before,
      *::after {
        box-sizing: inherit;
      }

      button,
      input,
      textarea,
      select {
        font-family: inherit;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 0;
      }
      ::selection {
        background: ${colors.primary};
        color: ${colors.white};
      }
      ::-moz-selection {
        background: ${colors.primary};
        color: ${colors.white};
      }

      .disable-scroll {
        overflow: hidden;
      }
    `}
  />
)

export default Global
