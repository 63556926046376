// @ts-check
import React from "react"

/**
 * Returns true if the viewport has passed a certain distance (in px)
 * or false otherwise
 * @param {number} distance
 * @returns {boolean}
 */
const useScrollState = distance => {
  const [value, setValue] = React.useState(false)

  React.useEffect(() => {
    if (
      (document.body.scrollTop || document.documentElement.scrollTop) > distance
    )
      setValue(true)

    const eventListener = () => {
      if (
        (document.body.scrollTop || document.documentElement.scrollTop) >
        distance
      )
        setValue(true)
      else setValue(false)
    }
    document.addEventListener("scroll", eventListener)

    return () => document.removeEventListener("scroll", eventListener)
  }, [distance])

  return value
}

export default useScrollState
