import styled from "@emotion/styled"
import React from "react"
import colors from "../styles/colors"

const Wrapper = styled.button`
  width: 100%;
  padding: 0;
  border: none;
  background-color: transparent;
  outline: none;
  position: relative;
`

const Line = styled.span`
  background-color: ${p => colors[p.headerColor]};
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;

  transform: ${p => !p.isOpen && `translateY(${p.position}px)`};
  transform: ${p => p.isOpen && !p.hiddenOnOpen && `rotate(${p.rotation}deg)`};
  transform: ${p => p.isOpen && p.hiddenOnOpen && "translateX(30vw)"};
  transition: transform 350ms;
`

const HamburgerButton = ({ headerColor, isOpen, onClick, ...props }) => {
  return (
    <Wrapper {...props} onClick={onClick}>
      <Line
        headerColor={headerColor}
        isOpen={isOpen}
        position="-8.5"
        rotation="45"
      />
      <Line
        headerColor={headerColor}
        isOpen={isOpen}
        position="0"
        hiddenOnOpen
      />
      <Line
        headerColor={headerColor}
        isOpen={isOpen}
        position="8.5"
        rotation="-45"
      />
    </Wrapper>
  )
}

export default HamburgerButton
