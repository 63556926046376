import styled from "@emotion/styled"
import React from "react"
import colors from "../styles/colors"
import useScrollState from "../hooks/useScrollState"
import { HeaderLogo } from "./icons/logos"

interface HeaderProps {
  hasScrolled: boolean
  variant: BackToProps["variant"]
}

const Header = styled.div<HeaderProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  height: 80px;

  display: flex;
  align-items: center;

  background-color: ${p => (p.hasScrolled ? colors.white : "transparent")};
  color: ${p =>
    p.hasScrolled
      ? colors.dark
      : p.variant === "dark"
      ? colors.darkgrey
      : colors.white};
`

const AbsoluteWrapper = styled.div`
  position: absolute;
  left: 50%;

  display: flex;
  align-items: center;
  justify-items: center;
`

const Link = styled.a`
  all: unset;

  cursor: pointer;
`

const Arrow = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7071 5.29289C13.3166 4.90237 12.6834 4.90237 12.2929 5.29289C11.9024 5.68342 11.9024 6.31658 12.2929 6.70711L16.5858 11L5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13L16.5858 13L12.2929 17.2929C11.9024 17.6834 11.9024 18.3166 12.2929 18.7071C12.6834 19.0976 13.3166 19.0976 13.7071 18.7071L19.7067 12.7075L19.7071 12.7071C19.8946 12.5196 20 12.2652 20 12C20 11.7411 19.8996 11.4925 19.7203 11.3064C19.716 11.3018 19.7116 11.2974 19.7071 11.2929L13.7071 5.29289Z"
    />
  </svg>
)

const ArrowWrapper = styled.div`
  height: 24px;
  width: 24px;
  transform: rotate(180deg);

  margin-right: 8px;
`

interface BackToProps {
  variant?: "light" | "dark"
  linkTo: "testdrive" | "roi"
}

export const BackTo = ({ variant, linkTo }: BackToProps) => {
  const hasScrolled = useScrollState(80)

  return (
    <Header hasScrolled={hasScrolled} variant={variant}>
      <div style={{ paddingLeft: 15 }}>
        <Link
          href={`https://www.mavenoid.com/en/${
            linkTo === "testdrive" ? linkTo : ""
          }`}
        >
          <ArrowWrapper>
            <Arrow />
          </ArrowWrapper>
        </Link>
      </div>
      <AbsoluteWrapper>
        <Link
          href={`https://www.mavenoid.com/`}
          style={{ transform: "translateX(-50%)" }}
        >
          <HeaderLogo />
        </Link>
      </AbsoluteWrapper>
    </Header>
  )
}
