// @ts-check
import React from "react"
import styled from "@emotion/styled"

import Global from "../styles/global"
import Header from "./header"
import Footer from "./footer"
import CookieBanner from "./cookieBanner"
import CardNewsletter from "./cards/cardNewsletter"
import { TColor } from "../styles/colors"
import { BackTo } from "./backTo"

const ContentWrapper = styled.main`
  margin: 0 auto;
  margin-top: 80px;
  padding: 0 4px;

  @media screen and (max-width: 768px) {
    margin-top: 60px;
  }
`

interface LayoutProps {
  bg?: TColor
  headerBg?: TColor
  headerColor?: TColor
  newsletter?: React.ReactNode
  demoButton?: React.ReactNode
  activeTab?: string
  className?: string
  /** use if component is used within webflow */
  webflow?: { linkTo: "testdrive" | "roi" }
  disableCookieBanner?: boolean
}

const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = ({
  bg = "white",
  headerBg,
  headerColor = "primary",
  newsletter = <CardNewsletter />,
  demoButton,
  activeTab,
  webflow,
  disableCookieBanner,
  className,
  children,
}) => (
  <>
    <Global bg={bg} />
    {webflow ? (
      <BackTo linkTo={webflow.linkTo} />
    ) : (
      <Header
        activeTab={activeTab}
        bg={headerBg ?? bg}
        demoButton={demoButton}
        headerColor={headerColor}
      />
    )}
    <ContentWrapper className={className}>
      {children}
      {newsletter}
      {!webflow && <Footer />}
    </ContentWrapper>
    {!webflow && !disableCookieBanner && <CookieBanner />}
  </>
)

export default Layout
