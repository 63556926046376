// @ts-check
import React from "react"

export const Check = (/** @type {React.SVGAttributes} */ { ...props }) => (
  <svg
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 13.7773L12.9497 18.7271L20.7279 10.9489"
      stroke="currentColor"
      strokeWidth="2.3"
      strokeLinecap="round"
    />
  </svg>
)
